/**
 * Flow:
 * 1. Get account list
 * 2. Once user selected an account
 *  2.1. Save profile id
 *  2.2. Get advertiser and site list by profile id
 * 3. Display advertiser and site selection
 * 4. Save integration details when user clicks 'Connect'
 */
import { FilterableSelectDropdown } from "../../../common/components/FilterableSelectDropdown.js"

const CM360 = {
    name: 'CM360',
    data: function () {
        return {
            accounts: [],
            advertisers: [],
            selected: {
                profile_id: null, // Following backend's Key name format
                account_id: null,
                account_name: null,
                advertiser_id: null,
                advertiser_name: null,
                subaccount_id: null
            },
            advertiserPageNumber: 1,
            isInit: false, // Hide form if account list if not fetched
            searchQuery: "",
            isLoading: {
                accounts: false,
                advertisers: false,
                sites: false
            },
            totalAdvertisersPages: 0
        }
    },
    props: {
        platform: Object,
        websiteName: String,
        integrationsService: Object
    },
    methods: {
        getAccountList() {
            this.integrationsService.gcm.getAccounts(this.platform.type).then(response => {
                this.accounts =  this.constructAccountsOptions(response)
                if(this.accounts.length === 1 && !this.selected.profile_id) {
                    this.selected.profile_id = this.accounts[0].value // account_name will be updated in constructDetails()
                }else{
                    this.isLoading.accounts = false
                }
                this.isInit = true
            })
        },
        constructAccountsOptions(dataArray) {
            return dataArray.map(item => ({
                value: item.profile_id,
                text: item.subaccount_name ? `${item.account_name} - ${item.subaccount_name}` : item.account_name,
                account_id: item.account_id,
                subaccount_id: item.subaccount_id || "",
            }));
        },
        updateProfileId() {
            this.integrationsService.update(this.platform.type, {
                settings: {
                  profile_id : this.selected.profile_id,
                  account_id: this.accounts.find(account => account.value === this.selected.profile_id).account_id,
                  account_name: this.accounts.find(account => account.value === this.selected.profile_id).text,
                  subaccount_id: this.accounts.find(account => account.value === this.selected.profile_id).subaccount_id,
                }
            }).then(response => {
                this.getAdvertisers()
            })
        },
        showMoreItems() {
            if(this.advertiserPageNumber === this.totalAdvertisersPages) return
            this.advertiserPageNumber++;
            this.getAdvertisers();
        },
        getAdvertisers(isFreshStart = false) {
            this.isLoading.advertisers = true
            const params = {};
            if (isFreshStart) {
                params.page = 1
            }
            else if (this.advertiserPageNumber > 1 && !this.searchQuery.length) {
                params.page = this.advertiserPageNumber
            }
            if(this.searchQuery.length) params.search = this.searchQuery
            this.integrationsService.gcm.getAdvertisers(this.platform.type, params).then(response => {
                if(this.totalAdvertisersPages !== response.total_pages) this.totalAdvertisersPages = response.total_pages
                if(this.advertiserPageNumber > 1 && !this.searchQuery.length) {
                    this.advertisers = this.advertisers.concat(...this.constructOptions(response.results)) // Concat new page advertisers into old advertisers array
                }else{
                    this.advertisers = this.constructOptions(response.results);
                }
                this.isLoading.advertisers = false
            })
        },
        constructDetails() {
            // Add profile and advertiser name to DB data field for later display
            this.selected.account_id = this.accounts.find(account => account.value === this.selected.profile_id).account_id
            this.selected.account_name = this.accounts.find(account => account.value === this.selected.profile_id).text
            this.selected.subaccount_id = this.accounts.find(account => account.value === this.selected.profile_id).subaccount_id
            if(this.selected.advertiser_id) this.selected.advertiser_name = this.advertisers.find(advertiser => advertiser.value === this.selected.advertiser_id).text
            return {
                type: this.platform.type,
                settings: {
                  ...this.selected,
                }
            }
        },
        constructOptions(dataArray, valueField = 'id') {
            return dataArray.map(item => ({
                value: item[valueField],
                text: item.name
            }));
        },
        // Functions for FilterableSelectDropdown
        onSearchQuery(searchQuery) {
            this.searchQuery = searchQuery
            if(searchQuery.length) {
                this.getAdvertisers();
            }else{
                this.getAdvertisers(true); // fresh start
            }
        },
        onSelectAdvertiser(advertiserId) {
            this.selected.advertiser_id = advertiserId
        }
    },
    mounted() {
        this.getAccountList();
    },
    computed: {
        componentProps() {
            return {
                placeholders: {
                    search: "Search advertiser",
                    firstOption: "Select advertiser"
                },
                data: this.advertisers,
                isLoading: this.isLoading.advertisers
            }
        },
        isAllSelected() {
            // Extract all values from the `selected` object where the key ends with '_id'
            const selectedIdValues = Object.entries(this.selected).reduce((acc, [key, value]) => {
                if (key.endsWith('_id')) {
                    acc.push(value);
                }
                return acc;
            }, []);

            // Check if all extracted values are not null
            return selectedIdValues.every(value => value !== null);
        }
    },
    components: {
        FilterableSelectDropdown
    },
    watch: {
        'selected.profile_id': function (newProfileId, oldProfileId) {
            // Check if accountId has actually changed
            if (newProfileId !== oldProfileId) {
                this.updateProfileId()
            }
        },
        selected: {
            handler: function (newSelected, oldSelected) {
                this.$emit('updateIntegrationDetails', this.constructDetails());
            },
            deep: true // Watch nested properties
        },
        isAllSelected(newValue, oldValue) {
            this.$emit("updateFormReadystate", newValue)
        }
    },
    template:
        `
    <div class="border p-4 rounded">

        <div v-if='!isInit' class="loading-overlay position-absolute d-flex justify-content-center align-items-center bg-white opacity-50" :class="{'hide': isInit}">
            <b-spinner label="Loading..."></b-spinner>
        </div>

        <b-form v-else="isInit">
            <div class="mb-2 position-relative">
                <label>Account</label>

                <div class="loading-overlay select-field-loading-overlay position-absolute d-flex justify-content-center align-items-center bg-white opacity-50 h-50" :class="{'hide': !isLoading.accounts}">
                    <b-spinner label="Loading..."></b-spinner>
                </div>

                <b-form-select v-model="selected.profile_id" :options="accounts">
                    <template #first>
                        <b-form-select-option :value="null" disabled>Select account</b-form-select-option>
                    </template>
                </b-form-select>
            </div>

            <div class="mb-2 position-relative" v-if="selected.profile_id">
                <label>Advertiser</label>
                <div class="loading-overlay select-field-loading-overlay position-absolute d-flex justify-content-center align-items-center bg-white opacity-50 h-50" :class="{'hide': !isLoading.advertisers}">
                    <b-spinner label="Loading..."></b-spinner>
                </div>
                <FilterableSelectDropdown v-bind="{...componentProps}" @onSearchQuery="onSearchQuery" @onSelectItem="onSelectAdvertiser" @showMoreItems="showMoreItems" />
            </div>
        </b-form>
    </div>
    `
}

export { CM360 };
