import { convertDatetimeToRelativeTime } from '../../../utils/date_utils.js';
import { useIntegrationStore } from '../../../store/IntegrationStore.js';
import { mapState } from 'pinia';

const Meta = {
  name: "MetaFeedStatusTemplate",
  data: function () {
    return ({
        loadingStatus: null,
        integrationStore: useIntegrationStore()
    })
},
  props: {
      getFeedTimeTries: Number,
      feedDetails: Object,
      platform: Object,
     // Status display
      statusDisplayValue: String,
      icon: Array,
      iconColorClass: String,
      isEnabling: Boolean
  },
  methods: {
    // FIXME: Feed details' last_updated only included date without time, cannot convert into relative time. We should update to us this method once we can get the time from feed details
    convertTimestamp(timestamp) {
      return convertDatetimeToRelativeTime(timestamp)
    }
  },
  computed: {
    ...mapState(useIntegrationStore, ['platformStatusMap']),
    isLoading: function() {
      if (!this.feedDetails) {
        this.loadingStatus = "Getting Feed Status"
        return true
      } else if (this.platform.status === this.platformStatusMap.ENABLING) {
        this.loadingStatus = "Creating Feed"
        return true
      }
      return false
    },
  },
  template:
  `
  <div class="meta-feed-status-template feed-status-template">
    <h6>
      <span>Catalog Status: </span>
      <font-awesome-icon
        v-if="icon.length"
        class="mr-2"
        :icon="icon"
        :class="iconColorClass"
        :spin="isEnabling"
      />
      <span>{{ isLoading ? loadingStatus : statusDisplayValue }}</span>
    </h6>

    <div v-if="getFeedTimeTries >= 10">
      <div class="alert alert-warning mb-0" role="alert">
        It is taking a longer amount of time than expected to create the feed. We
        might have encountered an unknown error. If this persists please contact
        support.
      </div>
    </div>

    <div v-if="isLoading" class="w-100" variant="outline">
      <p class="loading"></p>
    </div>

    <div class="feed-details" v-else>
      <div class="border-bottom py-2 d-flex justify-content-between">
        <p class="mb-0">Business:</p>
        <p class="mb-0">{{ platform.data.business_name }}</p>
      </div>
      <div class="border-bottom py-2 d-flex justify-content-between">
        <p class="mb-0">Catalog id:</p>
        <p class="mb-0">{{ platform.data.meta_catalog_id }}</p>
      </div>
      <div class="border-bottom py-2 d-flex justify-content-between">
        <p class="mb-0">Feed url:</p>
        <a class="mb-0" v-bind:href="platform.data.feed_url">{{ platform.data.feed_url }}</a>
      </div>
      <div>
        <div class="border-bottom py-2 d-flex justify-content-between">
          <p class="mb-0">Active Items:</p>
          <p class="mb-0">{{ this.feedDetails?.items_added }}</p>
        </div>
      </div>
      <div class="border-bottom py-2 d-flex justify-content-between">
        <p class="mb-0">Last feed update:</p>
        <p class="mb-0">{{ this.feedDetails?.last_updated }}</p>
      </div>
      <div class="border-bottom py-2 d-flex justify-content-between">
        <p class="mb-0">Items found:</p>
        <p class="mb-0">{{ this.feedDetails?.product_count }}</p>
      </div>
      <div class="border-bottom py-2 d-flex justify-content-between">
        <p class="mb-0">Items updated or added:</p>
        <p class="mb-0">{{ this.feedDetails?.items_added }}</p>
      </div>
      <div class="border-bottom py-2 d-flex justify-content-between">
        <p class="mb-0">Items rejected:</p>
        <p class="mb-0">{{ this.feedDetails?.items_rejected }}</p>
      </div>
      <div class="border-bottom pt-2 d-flex justify-content-between">
        <p class="mb-0">Items deleted:</p>
        <p class="mb-0">{{ this.feedDetails?.items_deleted }}</p>
      </div>

      <div class="mt-4">
        <div class="feed-error" v-if="feedDetails?.upload_errors?.length && !isLoading">
          <div v-for="error in feedDetails?.upload_errors">
            <div v-if="error.severity === 'fatal'">
              <b-alert
                v-for="error in feedDetails?.upload_errors"
                :key="error.summary"
                dismissible
                variant="warning"
                show
              >
                <p class="font-weight-bold">{{ error.summary }}</p>
                <p>{{ error.description }}</p>
                <p class="font-weight-lighter">
                  Items affected: {{ error.total_count }}
                </p>
              </b-alert>
            </div>

            <div v-else>
              <b-alert :key="error.summary" dismissible variant="warning" show>
                <p class="font-weight-bold">{{ error.summary }}</p>
                <p>{{error.description}}</p>
                <p class="font-weight-lighter">
                  Items affected: {{ error.total_count }}
                </p>
              </b-alert>
            </div>
          </div>
        </div>

        <div class="feed-warning" v-if="feedDetails?.warnings?.length && !isLoading">
          <b-alert
            v-for="warning in feedDetails?.warnings"
            :key="warning.code"
            dismissible
            variant="Danger"
            show
          >
            <p class="font-weight-bold">{{ warning.summary }}</p>
            <p>{{warning.description}}</p>
            <p class="font-weight-lighter">
              Items affected: {{warning.total_count}}
            </p>
          </b-alert>
        </div>
      </div>
    </div>
  </div>
`,

}

export { Meta };
